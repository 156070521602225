@import 'animate.css';
@import '~swiper/scss';
@tailwind base;
@layer base {
    body {
        @apply font-light pt-[74px] md:pt-[95px] overflow-x-hidden;
    }

    ::selection {
        @apply dark:bg-white dark:text-black;
    }

    .container {
        //Mobile / Tablet
        @apply w-full mx-auto px-7 relative;

        // Desktop
        @media screen and (min-width: 1278px) {
            //1246px
            @apply w-[984px] px-0;
        }

        @media screen and (min-width: 1440px) {
            //1246px
            @apply w-[1400px] px-0;
        }
    }

    strong {
        @apply font-medium;
    }
}

@tailwind components;
@layer components {
    .btn-cta-header {
        a {
            @apply font-normal bg-support-smooth-gray px-4 py-1;
        }
    }

    .btn {
        @apply relative inline-flex text-base dark:text-white font-medium px-[18px] h-[50px] items-center;

        &.primary {
            @apply transition-all ease-in-out hover:bg-gray;
        }

        svg {
            @apply ml-[18px];
        }
    }

    .swiper-button-disabled {
        @apply opacity-50;
    }

    .pin {
        @apply absolute cursor-pointer;
    }

    .breadcrumbs {
        @apply absolute top-[19px] left-0;

        > span {
            @apply flex gap-[10px] items-center;
        }

        a,
        span {
            @apply text-xs text-white;
        }

        .separator {
            @apply inline-block bg-breadcrumb-separator w-[16px] h-[16px];
        }

        strong {
            @apply font-normal;
        }
    }

    .fp-watermark {
        @apply hidden;
    }

    .modal-testimonial-info {
        @apply fixed min-h-[550px] max-h-[100vh] overflow-y-auto left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-orange-five w-full max-w-[980px] z-50 md:px-24 px-4 pt-16 pb-7 rounded-2xl transition-all hidden;
        &.open {
            @apply block;
        }
    }

    .modal-testimonial {
        @apply fixed left-1/2 top-1/2 max-h-[100vh] overflow-y-auto -translate-x-1/2 -translate-y-1/2 bg-darkblue w-full max-w-[980px] z-50 md:px-24 px-4 py-16 rounded-2xl transition-all hidden;
        &.open {
            @apply block;
        }
        &.form-sended {
            @apply max-w-[600px];
            form,
            .modal-text {
                @apply hidden;
            }

            .form-success {
                @apply block;
            }
        }
        form {
            @apply flex flex-wrap justify-between;

            div.error {
                @apply w-full text-sm text-orange-five mt-2 absolute left-5 -bottom-7;
            }
        }
        .fields {
            @apply border border-white rounded-[50px] w-full h-[60px] relative pl-5 flex items-center justify-between mt-9 flex-wrap;

            input {
                @apply h-full w-full;
            }

            select {
                @apply absolute opacity-0 left-0 top-0 w-full h-full m-0 p-0;
            }

            span {
                @apply text-sm text-white;
            }

            label {
                @apply uppercase bg-darkblue py-1 px-2 absolute -top-3 left-12 text-xs text-white;
            }
        }
        input,
        textarea {
            @apply bg-transparent text-white text-sm placeholder:text-white outline-none;
        }
    }
}

@tailwind utilities;
@layer utilities {
    .no-scrollbar::-webkit-scrollbar,
    .fp-overflow::-webkit-scrollbar {
        @apply hidden;
    }

    .no-scrollbar,
    .fp-overflow {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    .animation-play-state-paused {
        animation-play-state: paused;
    }
}

input.error {
    border-color: #ffb43c;
}

.otnotice-content {
    @apply relative;

    .otnotice-menu {
        @apply absolute;
    }

    .otnotice-version {
        margin: 0px !important;
        float: none !important;
        padding-bottom: 100px;
        text-align: right;
    }

    .otnotice-content {
        z-index: unset !important;
    }
}
